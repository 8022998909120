import Wix from '@wix/wix-events-commons-statics/dist/polyfills/wix-sdk'
import * as classNames from 'classnames'
import {IWixWindowViewMode} from 'native-components-infra/dist/es/src/types/types'
import * as React from 'react'
import {I18nextProvider} from 'react-i18next'
import {RuntimeContext} from '../../../commons/components/runtime-context/runtime-context'
import {Direction} from '../../../commons/constants/html'
import {getLanguage} from '../../../commons/selectors/environment'
import i18n from '../../i18n'
import {AppLoaded} from '../app-loaded'
import {ErrorBoundary} from '../error-boundary'
import {Layout} from '../layout'
import * as s from './app.scss'
import {AppProps} from './interfaces'

export class App extends React.Component<AppProps> {
  state = {scale: 1}

  getI18n = () => {
    const language = getLanguage(this.props.state)
    const translation = this.props.state.translation

    return i18n(language, {[language]: {translation}})
  }

  render() {
    if (!this.props.state) {
      return null
    }

    const {
      host: {
        viewMode,
        dimensions: {height},
        appLoadBI: {loaded}
      },
      actions: {widgetLoaded},
      isRTL,
      pageInfo: {isHomePage}
    } = this.props

    Wix.getBoundingRectAndOffsets(({scale}) => {
      if (scale && this.state.scale !== scale) {
        this.setState({scale})
      }
    })

    this.props.host.dimensions.height = Math.round(height / this.state.scale)

    return (
      <ErrorBoundary raven={this.props.host.raven}>
        <RuntimeContext.Provider value={this.props}>
          <I18nextProvider i18n={this.getI18n()}>
            <div
              id="wix-events-widget"
              className={classNames(s.root, {eventsRtl: isRTL})}
              dir={isRTL ? Direction.RTL : Direction.LTR}
            >
              <AppLoaded
                appLoaded643={loaded}
                widgetLoaded={widgetLoaded}
                viewMode={viewMode as IWixWindowViewMode}
                isHomePage={isHomePage}
              />
              <Layout />
            </div>
          </I18nextProvider>
        </RuntimeContext.Provider>
      </ErrorBoundary>
    )
  }
}
