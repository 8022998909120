import {RavenStatic} from 'raven-js'
import * as React from 'react'
import {SENTRY_DSN} from '../../constants/app'

export class ErrorBoundary extends React.Component<{raven: RavenStatic}> {
  state = {
    hasError: false
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error
    }
  }

  componentDidMount(): void {
    if (this.props.raven) {
      this.props.raven.config(SENTRY_DSN)
    }
  }

  componentDidCatch(error, info) {
    console.log(error, info)
    if (this.props.raven) {
      this.props.raven.captureException(error)
    }
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>
    }

    return this.props.children
  }
}
